.imageContent {
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .images {
    margin-top: 10vmin !important;
  }
}
