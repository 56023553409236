.hidden {
  bottom: -200px;
}

.imageDetails {
  position: absolute;
  bottom: -200px;
  right: 0;
  height: 5vh;
  width: 100%;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}

.imageTitle {
  width: 50%;
}
