.wrapper {
  z-index: 1;
  top: 0;
  width: 100%;
  height: 15vmin;
  background: white;
  text-align: center;
  visibility: hidden;
  display: none;
}

h1 {
  color: #4f4f4f;
  margin-top: "1px";
  line-height: 15vmin;
  display: inline-block;
  font-weight: 500;
  font-size: 1.75rem !important;
}

@media screen and (max-width: 800px) {
  .wrapper {
    position: sticky !important;
    display: block;
    visibility: visible;
  }
}

.logo {
  width: 5vmin;
  margin-right: 2vmin;
  position: relative;
  top: 1px;
}
