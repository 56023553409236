.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  font-size: 32px;
  margin: 0;
}

section {
  margin-bottom: 10vh;
}
