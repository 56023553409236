.navbar {
  width: 270px;
  background: white;
  z-index: 3;
  position: fixed !important;
}

.menuButton {
  position: fixed;
  left: 5vmin;
  top: 5vmin;
  z-index: 4 !important;
  border: none;
  background: none;
  padding: 0;
}

.navigation ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.navigation h2 {
  margin-top: 5vmin;
  margin-left: 5vmin;
  color: #4f4f4f;
}

.navigation li {
  margin: 1vmin 5vmin;
}

.navigation a {
  text-decoration: none;
  color: #646464;
  transition: all 100ms ease-in-out;
}

.navigation a:hover,
.navigation a:focus {
  color: #00826c !important;
}

.navigation a:visited {
  color: #646464;
}

.navigation li.active > a {
  color: #00826c;
  font-weight: 500;
}

.imageWrapper + .imageWrapper {
  margin-top: 5vmin;
}
